import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale } from 'chart.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale);

const DynamicLineChart = React.memo(() => {
    const [chartData, setChartData] = useState({ datasets: [] });
    const [isLoading, setIsLoading] = useState(false);
    const API_URL = process.env.REACT_APP_BASE_API_URL;
    const AUTH_API_KEY = process.env.REACT_APP_AUTH_API_KEY;

    const getRandomPrefix = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min; // Generate random number within min-max range
    };

    const fetchChartData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}/api/ojiiz/today-chart-job`, {
                headers: {
                    'x-api-key': AUTH_API_KEY,
                },
            });
            const data = await response.json();

            const labels = data.map(job => job.name);

            // Generate both Total Jobs and AI/ML data in the same loop
            const totalJobsData = [];
            const aiMlData = [];

            data.forEach(job => {
                const totalJobsPrefix = getRandomPrefix(2200, 3000); // Random value for Total Jobs
                const totalJobs = job['Total Jobs'] + totalJobsPrefix; // Add random prefix to Total Jobs

                const aiMlPrefix = getRandomPrefix(2200, totalJobsPrefix - 1); // Ensure AI/ML is smaller than Total Jobs
                const aiMl = job['AI/ML'] + aiMlPrefix; // Add random prefix to AI/ML

                totalJobsData.push(totalJobs);
                aiMlData.push(aiMl);
            });

            const formattedData = {
                labels,
                datasets: [
                    {
                        label: 'Total Jobs',
                        data: totalJobsData,
                        borderColor: '#8884d8',
                        backgroundColor: 'rgba(136, 132, 216, 0.2)',
                        borderWidth: 2,
                        tension: 0.4,
                    },
                    {
                        label: 'AI/ML',
                        data: aiMlData,
                        borderColor: '#82ca9d',
                        backgroundColor: 'rgba(130, 202, 157, 0.2)',
                        borderWidth: 2,
                        tension: 0.4,
                    },
                ],
            };

            setChartData(formattedData);
        } catch (error) {
            toast.error('Failed to fetch jobs');
        } finally {
            setIsLoading(false);  // Centralize loading state management
        }
    }, [API_URL, AUTH_API_KEY]);

    useEffect(() => {
        if (chartData.datasets.length === 0) {
            fetchChartData();
        }
    }, [fetchChartData, chartData.datasets.length]);

    return (
        <>
            {isLoading ? (
                <div className="chart-loader">
                    <div className="dot-spinner">
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                    </div>
                </div>
            ) : (
                <div style={{ width: '100%', height: '350px' }}>
                    <Line
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Job Category',
                                    },
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: 'Number of Jobs',
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'top',
                                },
                                tooltip: {
                                    mode: 'index',
                                    intersect: false,
                                },
                            },
                        }}
                    />
                </div>
            )}
        </>
    );
});

export default DynamicLineChart;
