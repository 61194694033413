import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResend, setIsResend] = useState(false);
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.warning('Please enter your email address');
            return;
        }
        setIsLoading(true);

        try {
            const response = await fetch(`${API_URL}/api/ojiiz/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json(); // Parse the JSON response

            if (response.ok) {
                toast.success(data.msg);
                setIsResend(true);
            } else if (response.status === 400) {
                toast.warning(data.msg);  // Properly display error for Google users or non-existent users
            } else {
                toast.error('Error sending email. Please try again.');
            }
        } catch (error) {
            toast.error('Error sending email. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className='sign-in'>
            <ToastContainer />
            <div className="forgot-mod">
                <div className="forgot-content">
                    <h1>Reset Your Password</h1>
                    <form onSubmit={handleSubmit}>
                        <p>Enter your email address below, and we will send you a link to reset your password.</p>
                        <input
                            type="email"
                            placeholder='Enter your email address'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit" disabled={isLoading} className='primary-btn'>
                            {isLoading ? 'Processing...' : isResend ? 'Resend Link' : 'Send Link'}
                        </button>
                        {isResend && <p>Didn’t receive the email? Click "Resend Link" to try again.</p>}
                        <p className="info-text">Go Back to <Link to={"/"}>Login page</Link></p>
                    </form>
                </div>
            </div>
            <div className="sign-content"></div>
            <div className="fade-in"></div>
        </div>
    );
};

export default ForgotPassword;
