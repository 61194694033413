import React, { useState, useEffect, useRef, useMemo } from 'react';
import { NavBar, SideBar, FieldSelectionPopup, Footer } from '../components';
import { useAuthContext } from '../hooks/useAuthContext';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
import saveJobImg from '../assets/save-job.png';

const ExportCsv = () => {
    const [savedJobs, setSavedJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
    const [showFieldPopup, setShowFieldPopup] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const csvLinkRef = useRef(); // Reference for CSVLink

    const { ojiiz_user } = useAuthContext();
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        const fetchSavedJobs = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${API_URL}/api/ojiiz/user-saved-jobs/${ojiiz_user.userName}`, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setSavedJobs(data.savedJobs.map(job => ({
                        ...job,
                        mainCredit: job.mainCredit,  // Get mainCredit from job data
                        phoneCredit: job.phoneCredit,  // Get phoneCredit from job data
                        isDeleted: false
                    })));
                } else {
                    toast.error(data.error);
                }
            } catch (error) {
                toast.error('Error fetching saved jobs');
            } finally {
                setIsLoading(false);
            }
        };

        fetchSavedJobs();
    }, [API_URL, ojiiz_user.userName]);


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentPage]);

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms delay for debounce

        return () => clearTimeout(handler);
    }, [searchTerm]);

    const currentJobsFiltered = useMemo(() => {
        return savedJobs.filter(
            job =>
                !job.isDeleted &&
                (job.jobTitle?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
                    job.jobCategory?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
        );
    }, [savedJobs, debouncedSearchTerm]);



    const currentJobs = currentJobsFiltered.slice(indexOfFirstJob, indexOfLastJob);

    const paginate = pageNumber => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };


    const totalPages = Math.ceil(currentJobsFiltered.length / jobsPerPage);
    const maxPageButtons = 5;

    const getPageNumbers = () => {
        const pages = [];
        let startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
        let endPage = startPage + maxPageButtons - 1;

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(endPage - maxPageButtons + 1, 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    const handleCheckboxChange = (job) => {
        setSelectedJobs(prevSelectedJobs => {
            if (prevSelectedJobs.some(selectedJob => selectedJob._id === job._id)) {
                return prevSelectedJobs.filter(selectedJob => selectedJob._id !== job._id);
            } else {
                return [...prevSelectedJobs, job];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedJobs.length === currentJobs.length) {
            setSelectedJobs([]);
        } else {
            setSelectedJobs([...currentJobs]);
        }
    };

    const allHeaders = [
        { label: "Sr#", key: "srNo" },
        { label: "Job Title", key: "jobTitle" },
        { label: "Job Category", key: "jobCategory" },
        { label: "Company Name", key: "companyName" },
        { label: "Posted By", key: "postedBy" },
        { label: "Email", key: "email" },
        { label: "Linkedin", key: "linkedin" },
        { label: "Company Phone", key: "companyPhone" },
        { label: "Job Date", key: "jobDate" },
        { label: "Job Description", key: "jobDescription" }
    ];

    const htmlToPlainText = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const prepareCsvData = (selectedFields) => {
        const data = selectedJobs.map((job, index) => {
            const jobDate = new Date(job.jobDate);
            const formattedJobDate = jobDate.toISOString().split('T')[0];
            const plainTextJobDescription = htmlToPlainText(job.jobDetail);

            const jobData = {
                srNo: index + 1,
                jobTitle: job.jobTitle,
            };

            if (selectedFields.includes("jobCategory")) jobData.jobCategory = job.jobCategory;
            if (selectedFields.includes("companyName")) jobData.companyName = job.mainCredit ? job.companyName : 'Buy oz to access these Info';
            if (selectedFields.includes("postedBy")) jobData.postedBy = job.mainCredit ? job.postedBy : 'Buy oz to access these Info';
            if (selectedFields.includes("email")) jobData.email = job.mainCredit ? job.email : 'Buy oz to access these Info';
            if (selectedFields.includes("linkedin")) jobData.linkedin = job.mainCredit ? job.linkedin : 'Buy oz to access these Info';
            if (selectedFields.includes("companyPhone")) jobData.companyPhone = job.mainCredit && job.phoneCredit ? job.companyPhone : 'Buy oz to access these Info';
            if (selectedFields.includes("jobDate")) jobData.jobDate = formattedJobDate;
            if (selectedFields.includes("jobDescription")) jobData.jobDescription = plainTextJobDescription;

            return jobData;
        });

        // Set csv headers based on selected fields
        const headers = allHeaders.filter(header => selectedFields.includes(header.key) || header.key === "srNo" || header.key === "jobTitle");
        setCsvHeaders(headers);

        return data;
    };

    const handleExport = (selectedFields) => {
        const data = prepareCsvData(selectedFields);
        setCsvData(data);
        setShowFieldPopup(false);
        setTimeout(() => {
            csvLinkRef.current.link.click(); // Trigger CSV download
        }, 100);
    };

    const handleDeleteSelectedJobs = async () => {
        const confirmed = window.confirm('Are you sure you want to delete the selected jobs?');
        if (!confirmed) {
            return;
        }

        try {
            // Collect selected job IDs
            const jobIds = selectedJobs.map(job => job._id);

            // Send the list of IDs in the body of a single DELETE request
            const response = await fetch(`${API_URL}/api/ojiiz/user-profile/${ojiiz_user.userName}/delete-selected-jobs`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
                body: JSON.stringify({ jobIds }), // Send the IDs in the request body
            });

            if (!response.ok) {
                throw new Error('Failed to delete selected jobs');
            }

            // Update state after successful deletion
            setSavedJobs(prevJobs => prevJobs.filter(job => !jobIds.includes(job._id)));
            setSelectedJobs([]); // Clear selected jobs
            toast.success('Selected jobs deleted successfully');
        } catch (error) {
            toast.error('Failed to delete selected jobs');
        }
    };


    const truncateText = (content, maxLength) => {
        return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
    };

    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar home={true} />
                <h1>Export CSV</h1>
            </div>
            <ToastContainer />
            {isLoading && <div className="loader"></div>}

            <div className="saved-content">
                <SideBar />
                <div className="export-csv-job">
                    <div className="export-header">
                        <div className="export-header-text">
                            <h2>Jobs Data</h2>
                            <p>Total saved jobs: <span>{currentJobsFiltered?.length}</span></p>
                        </div>
                        <div className='export-header-btn'>
                            <input
                                className='search-input'
                                type="text"
                                placeholder="Search by job title..."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <button
                                onClick={handleSelectAll}
                                className="secondary-button"
                                style={{ marginRight: '10px' }}
                            >
                                {selectedJobs.length === currentJobs.length ? 'Deselect All' : 'Select All'}
                            </button>

                            <button
                                onClick={handleDeleteSelectedJobs} // Added delete button
                                className="delete-button"
                                disabled={selectedJobs.length === 0}
                                style={{ marginRight: '10px' }}
                            >
                                Delete Selected
                            </button>

                            <button
                                onClick={() => setShowFieldPopup(true)}
                                className="primary-button"
                                disabled={selectedJobs.length === 0}
                            >
                                Export CSV
                            </button>
                        </div>
                    </div>

                    {savedJobs.length > 0 ? (
                        <div>
                            <div className="table-wrapper">
                                <table className='fl-table'>
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Job Title</th>
                                            <th>Category</th>
                                            <th>Company Name</th>
                                            <th>Company Phone</th>
                                            <th>Job Date</th>
                                            <th>Actions</th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentJobs.map((job, index) => (
                                            job.isDeleted ? (
                                                <tr key={job._id} className={job.isDeleted ? 'delete-job' : ''}>
                                                    <td>{index + 1}</td>
                                                    <td colSpan="9">This job no longer exists.</td>
                                                </tr>
                                            ) : (
                                                <tr key={job._id}>
                                                    <td>{indexOfFirstJob + index + 1}</td>
                                                    <td>{truncateText(job.jobTitle, 30)}</td>
                                                    <td>{job.jobCategory}</td>
                                                    <td>
                                                        {job.mainCredit ? job.companyName :
                                                            <p className='not-access'>
                                                                Not Access
                                                            </p>
                                                        }</td>
                                                    <td>
                                                        {job.phoneCredit ? job.companyPhone :
                                                            <p className='not-access'>
                                                                Not Access
                                                            </p>
                                                        }</td>
                                                    <td>{job.jobDate.split('T')[0]}</td>
                                                    <td>
                                                        <Link to={`/jobs-detail/${job._id}`}><FaEye size={24} className='icon' color='#243459' /></Link>
                                                    </td>
                                                    <td>
                                                        <label className="cyberpunk-checkbox-label">
                                                            <input
                                                                type="checkbox"
                                                                className="cyberpunk-checkbox"
                                                                onChange={() => handleCheckboxChange(job)}
                                                                checked={selectedJobs.some(selectedJob => selectedJob._id === job._id)}
                                                            />
                                                        </label>
                                                    </td>
                                                </tr>
                                            )
                                        ))}


                                    </tbody>

                                </table>
                            </div>

                            <div className="pagination">
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                    onClick={() => paginate(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Prev
                                </button>
                                {getPageNumbers().map((number) => (
                                    <button
                                        key={number}
                                        onClick={() => paginate(number)}
                                        className={currentPage === number ? 'active' : ''}
                                    >
                                        {number}
                                    </button>
                                ))}
                                <button
                                    onClick={() => paginate(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    ) : (
                        <img src={saveJobImg} alt="" width={400} className='no-item' />
                    )}
                </div>

            </div>

            <Footer />
            {showFieldPopup && (
                <FieldSelectionPopup
                    availableFields={allHeaders}
                    onClose={() => setShowFieldPopup(false)}
                    onExport={handleExport}
                />
            )}

            {csvData.length > 0 && (
                <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={"saved_jobs.csv"}
                    className="primary-button"
                    ref={csvLinkRef} // Attach ref to CSVLink
                    style={{ display: 'none' }}
                >
                    Export CSV
                </CSVLink>
            )}
        </div>
    );
};

export default ExportCsv;
