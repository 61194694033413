import React from 'react';
import { FaBuilding } from 'react-icons/fa';
import { CiCalendar } from 'react-icons/ci';
import { GoDotFill } from "react-icons/go";
import { formatDistanceToNow, parseISO, subHours } from 'date-fns';

// Import all icons
import AIIcon from '../assets/job-icon/AI.png';
import CMSIcon from '../assets/job-icon/CMS.png';
import CWIcon from '../assets/job-icon/CW.png';
import DevopsIcon from '../assets/job-icon/Devops.png';
import DMIcon from '../assets/job-icon/DM.png';
import GamingIcon from '../assets/job-icon/Gaming.png';
import GDIcon from '../assets/job-icon/GD.png';
import AppIcon from '../assets/job-icon/App.png';
import SEOIcon from '../assets/job-icon/SEO.png';
import UIIcon from '../assets/job-icon/UI.png';
import WebIcon from '../assets/job-icon/Web.png';
import BlockchainIcon from '../assets/job-icon/Blockchain.png';
import OthersIcons from '../assets/job-icon/Others.png';

// for tag icons
import Individual from '../assets/tag-icon/individual.png';
import Multi from '../assets/tag-icon/mix.png';
import Hourly from '../assets/tag-icon/hourly.png';
import FixedCost from '../assets/tag-icon/fixed-cost.png';
import Agencey from '../assets/tag-icon/agencey.webp';


// Create a mapping between job categories and icons
const categoryIcons = {
    'AI/ML/Computer Vision': AIIcon,
    'CMS': CMSIcon,
    'Content Writing': CWIcon,
    'DevOps/Database': DevopsIcon,
    'Digital Marketing': DMIcon,
    'Games': GamingIcon,
    'Graphic Designer': GDIcon,
    'Mobile App Development': AppIcon,
    'Google Ads/SEO': SEOIcon,
    'UI/UX Designer': UIIcon,
    'Web Development': WebIcon,
    'Blockchain/NFTs': BlockchainIcon,
    'Other': OthersIcons
};

// Define colors and icons for specific tags
const tagStyles = {
    'Fixed Cost': { color: '#33AAB6', icon: FixedCost },
    'Hourly': { color: '#48bb78', icon: Hourly },
    'Agency Hiring': { color: '#4299e1', icon: Agencey },
    'Individual Hiring': { color: '#ed64a6', icon: Individual },
    'Multi-source Hiring': { color: '#DB9E4F', icon: Multi }
};

const Jobs = ({ job }) => {
    // Parse jobDate using parseISO to ensure it's in UTC
    const jobDate = parseISO(job.jobDate);
    // Adjust for the 5-hour difference
    const adjustedJobDate = subHours(jobDate, 5);
    // Calculate the time ago from now
    const timeAgo = formatDistanceToNow(adjustedJobDate, { addSuffix: true, includeSeconds: true });

    // Function to extract and truncate content, skipping headings
    const extractAndTruncateContent = (htmlString, maxLength) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        const childNodes = tempElement.childNodes;
        let content = '';

        for (let i = 0; i < childNodes.length; i++) {
            const node = childNodes[i];
            // Skip if it's a heading tag
            if (node.nodeName.toLowerCase().startsWith('h')) {
                continue;
            }
            // Concatenate text content if it's not empty
            if (node.textContent.trim()) {
                content += node.textContent.trim() + ' ';
            }
            // Break loop if content exceeds maxLength
            if (content.length >= maxLength) {
                break;
            }
        }

        // Truncate content to maxLength
        return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
    };

    // Extract and truncate the content, skipping headings
    const truncatedContent = extractAndTruncateContent(job.jobDetail, 150);

    const truncateText = (content, maxLength) => {
        return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
    };

    // Get the icon based on job category
    const jobIcon = categoryIcons[job.jobCategory] || FaBuilding;

    // Split tags by commas and trim any extra whitespace
    const tagsArray = job.tags ? job.tags.split(',').map(tag => tag.trim()) : [];

    return (
        <div className="job-box">
            <div className="job-building-icon">
                <img src={jobIcon} alt={job.jobCategory} />
            </div>
            <div className="job-description">
                <h3>{job.jobTitle && truncateText(job.jobTitle, 35)}</h3>

                <div className="tags-list">
                    {tagsArray.map((tag, index) => {
                        const { color, icon } = tagStyles[tag] || { color: '#cbd5e0', icon: null };
                        return (
                            <div
                                key={index}
                                className="tag"
                                style={{
                                    color: color, // Text color only
                                    display: 'flex',
                                    border: `1px solid ${color}` // Optional border for better visibility
                                }}
                            >
                                {icon &&
                                    <img src={icon} alt={icon} style={{ marginRight: '0.2rem' }} width={24} height={24} />

                                }
                                <span>{tag}</span>
                            </div>
                        );
                    })}
                </div>


                <span className='cal-icon'>
                    <CiCalendar /> {timeAgo} <GoDotFill size={14} /> {job.jobCategory}
                </span>
                <p>{truncatedContent}</p>
            </div>
        </div>
    );
};

export default Jobs;
