import React, { createContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const JobsContext = createContext();

const JobsProvider = ({ children }) => {
    const [jobs, setJobs] = useState([]);
    const [totalJobs, setTotalJobs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [jobCounts, setJobCounts] = useState([]);

    const fetchJobCounts = async (jobDate = '') => {

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/ojiiz/job-counts?jobDate=${jobDate}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                toast.error('Failed to fetch jobs');
            }
        } catch (error) {
            toast.error('Failed to fetch jobs');
        }
        return [];
    };

    const fetchJobs = useCallback(async (page, filters, sortBy, searchQuery, isSearch, limit) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoading(true);
        // console.log(
        //     searchQuery
        // )
        // console.log(
        //     isSearch
        // )
        // console.log(
        //     filters
        // )
        try {
            let apiUrl = '';
            const filtersQuery = new URLSearchParams({
                page,
                limit,
                sortBy, // Add sortBy to the query parameters
            }).toString();

            if (isSearch || searchQuery.length > 0) {
                // console.log('insearc')
                apiUrl = `${process.env.REACT_APP_BASE_API_URL}/api/ojiiz/search-job?query=${searchQuery}&${filtersQuery}&jobDate=${filters.jobDate.join(',')}`;
            } else if (filters.jobCategory.length > 0) {
                // console.log('infilter')
                apiUrl = `${process.env.REACT_APP_BASE_API_URL}/api/ojiiz/filter-job?${filtersQuery}`;
            } else {
                // console.log('def')
                apiUrl = `${process.env.REACT_APP_BASE_API_URL}/api/ojiiz/latest-job?${filtersQuery}&jobDate=${filters.jobDate.join(',')}`;
            }

            const response = await fetch(apiUrl, {
                method: filters.jobCategory.length > 0 ? 'POST' : 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
                body: filters.jobCategory.length > 0 ? JSON.stringify(filters) : null,
            });

            if (response.ok) {
                const { data, total } = await response.json();
                setJobs(data);
                setTotalJobs(total);
                setCurrentPage(page);
                setTotalPages(Math.ceil(total / limit));
            } else {
                toast.error(isSearch.length > 0 ? 'Failed to fetch search results:' : 'Failed to fetch jobs:', response.statusText);
            }

            const jobCounts = await fetchJobCounts(filters.jobDate.length > 0 ? filters.jobDate : '');
            setJobCounts(jobCounts);
        } catch (error) {
            toast.error('Failed to fetch jobs');
        } finally {
            setIsLoading(false);
        }
    }, []);



    return (
        <JobsContext.Provider value={{ jobs, totalJobs, isLoading, currentPage, totalPages, fetchJobs, setCurrentPage, pageLimit, setPageLimit, jobCounts }}>
            {children}
        </JobsContext.Provider>
    );
};

export default JobsProvider;
