import React, { useState, useEffect, useRef } from 'react';
import './components.css';
import logo from '../assets/logo.png';
import profile from '../assets/profile.png';
import { ChevronDown } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../context/AdminLogout';
import { useUserCredit } from '../context/UserCreditContext';
import { FaUserTie, FaPowerOff, FaAlignCenter, FaCaretDown } from "react-icons/fa6";
import { IoBookmark } from "react-icons/io5";

import AnimatedCounter from "./AnimatedCounter";

// Import all icons
import AIIcon from '../assets/job-icon/AI.png';
import CMSIcon from '../assets/job-icon/CMS.png';
import CWIcon from '../assets/job-icon/CW.png';
import DevopsIcon from '../assets/job-icon/Devops.png';
import DMIcon from '../assets/job-icon/DM.png';
import GamingIcon from '../assets/job-icon/Gaming.png';
import GDIcon from '../assets/job-icon/GD.png';
import AppIcon from '../assets/job-icon/App.png';
import SEOIcon from '../assets/job-icon/SEO.png';
import UIIcon from '../assets/job-icon/UI.png';
import WebIcon from '../assets/job-icon/Web.png';
import BlockchainIcon from '../assets/job-icon/Blockchain.png';
import ojiiz_head from '../assets/Ojiiz.png';

// Create a mapping between job categories and icons
const categoryIcons = {
    'AI/ML/Computer Vision': AIIcon,
    'CMS': CMSIcon,
    'Content Writing': CWIcon,
    'DevOps/Database': DevopsIcon,
    'Digital Marketing': DMIcon,
    'Games': GamingIcon,
    'Graphic Designer': GDIcon,
    'Mobile App Development': AppIcon,
    'Google Ads/SEO': SEOIcon,
    'UI/UX Designer': UIIcon,
    'Web Development': WebIcon,
    'Blockchain/NFTs': BlockchainIcon
};

const CategoryItem = ({ displayCategory, sendCategory, handleCategoryClick }) => (
    <span>
        <img src={categoryIcons[sendCategory]} alt={displayCategory} />
        <li onClick={() => handleCategoryClick(sendCategory)}>
            {displayCategory}
        </li>
    </span>
);

const NavBar = ({ home }) => {
    const { ojiiz_user } = useAuthContext();
    const { Credit } = useUserCredit();

    const { logout } = useLogout();
    // const [showMenu, setShowMenu] = useState(false);
    const [showMenuCat, setShowMenuCat] = useState(false);
    // const [showMenuProfile, setShowMenuProfile] = useState(false);
    const [activeItem, setActiveItem] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const handleCategoryClick = (category) => {
        setShowMenuCat(false)
        navigate('/jobs', { state: { category } });
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleClickLogout = () => {
        logout();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setDropdownVisible(false);
        const path = location.pathname;
        if (path === '/') setActiveItem('Home');
        else if (path === '/jobs') setActiveItem('Jobs');
        else setActiveItem(null);
    }, [location]);

    return (
        <div className='navbar'>
            <Link to={"/"}>
                <img src={logo} alt="logo" />
            </Link>

            {home &&
                <ul className='nav-item'>
                    <Link to={"/"}>
                        <li className={`nav-link ${activeItem === 'Home' ? 'active' : ''}`} onClick={() => setActiveItem('Home')}>Home</li>
                    </Link>
                    <li className='nav-link'>
                        Categories <ChevronDown />
                        <div className="dropdown-content">
                            <ul>
                                <div className="row-">
                                    <div className="col">
                                        <CategoryItem displayCategory="CMS" sendCategory="CMS" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="Web Development" sendCategory="Web Development" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="App Development" sendCategory="Mobile App Development" handleCategoryClick={handleCategoryClick} />
                                    </div>
                                    <div className="col">
                                        <CategoryItem displayCategory="Gaming" sendCategory="Games" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="Graphic Design" sendCategory="Graphic Designer" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="Blockchain and NFTs" sendCategory="Blockchain/NFTs" handleCategoryClick={handleCategoryClick} />
                                    </div>
                                    <div className="col">
                                        <CategoryItem displayCategory="AI/ML/Computer Vision" sendCategory="AI/ML/Computer Vision" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="UI/UX Design" sendCategory="UI/UX Designer" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="DevOps/Database" sendCategory="DevOps/Database" handleCategoryClick={handleCategoryClick} />
                                    </div>
                                    <div className="col">
                                        <CategoryItem displayCategory="Digital Marketing" sendCategory="Digital Marketing" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="Content Writing" sendCategory="Content Writing" handleCategoryClick={handleCategoryClick} />
                                        <CategoryItem displayCategory="Google Ads and SEO" sendCategory="Google Ads/SEO" handleCategoryClick={handleCategoryClick} />
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </li>
                    <Link to={"/jobs"}>
                        <li className={`nav-link ${activeItem === 'Jobs' ? 'active' : ''}`} onClick={() => setActiveItem('Jobs')}>Jobs</li>
                    </Link>
                </ul>
            }
            {home &&
                <p className='nav-mob-item' onClick={() => setShowMenuCat(!showMenuCat)}>
                    Category <ChevronDown size={14} />
                </p>
            }
            {showMenuCat &&
                <div className="modal category-modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                className="close-button"
                                onClick={() => {
                                    setShowMenuCat(!showMenuCat);
                                }}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="dropdown-content">
                                <ul>
                                    <div className="row-">
                                        <div className="col">
                                            <CategoryItem displayCategory="CMS" sendCategory="CMS" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="Web Development" sendCategory="Web Development" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="App Development" sendCategory="Mobile App Development" handleCategoryClick={handleCategoryClick} />
                                        </div>
                                        <div className="col">
                                            <CategoryItem displayCategory="Gaming" sendCategory="Games" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="Graphic Design" sendCategory="Graphic Designer" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="Blockchain and NFTs" sendCategory="Blockchain/NFTs" handleCategoryClick={handleCategoryClick} />
                                        </div>
                                        <div className="col">
                                            <CategoryItem displayCategory="AI/ML/Computer Vision" sendCategory="AI/ML/Computer Vision" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="UI/UX Design" sendCategory="UI/UX Designer" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="DevOps/Database" sendCategory="DevOps/Database" handleCategoryClick={handleCategoryClick} />
                                        </div>
                                        <div className="col">
                                            <CategoryItem displayCategory="Digital Marketing" sendCategory="Digital Marketing" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="Content Writing" sendCategory="Content Writing" handleCategoryClick={handleCategoryClick} />
                                            <CategoryItem displayCategory="Google Ads and SEO" sendCategory="Google Ads/SEO" handleCategoryClick={handleCategoryClick} />
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* for show profile detail */}
            <div className="nav-acc" ref={dropdownRef}>
                <div className="profile" onClick={toggleDropdown}>
                    <span>
                        <FaCaretDown size={20} className={`icon-down ${dropdownVisible ? 'rotate' : ''}`} />
                        <p>{ojiiz_user?.userName}</p>
                    </span>
                    <img src={profile} alt="profile" />
                </div>

                {dropdownVisible && (
                    <div className="nav-profile-dropdown">
                        <div className="nav-profile-dropdown-header">
                            <h3>{ojiiz_user?.fullName}</h3>
                            <Link to={"/choose-plan"}>
                                <div className="profile-type">
                                    {/* {ojiiz_user?.planType} */}
                                    Upgrade
                                </div>
                            </Link>
                        </div>
                        <div className="divider"></div>
                        <ul>
                            <Link to={"/profile"} onClick={() => setDropdownVisible(false)}>
                                <li><FaUserTie />Profile</li>
                            </Link>
                            {/* <div className="divider"></div> */}
                            <Link to={"/saved-jobs"} onClick={() => setDropdownVisible(false)}>
                                <li><IoBookmark />Saved Jobs</li>
                            </Link>
                            {/* <div className="divider"></div> */}
                            <Link to={"/choose-plan"} onClick={() => setDropdownVisible(false)}>
                                <li><FaAlignCenter />Choose Plan</li>
                            </Link>
                            <div className="divider"></div>

                            <li onClick={handleClickLogout}><FaPowerOff />Logout</li>
                        </ul>
                    </div>
                )}
                {/* to show user credit */}
                <div className="my-plan">
                    <div className="my-plan-credit">
                        <img src={ojiiz_head} alt="" width={24} height={24} />
                        <p>

                            {Credit
                                ?
                                <AnimatedCounter value={Credit} />
                                : 0
                            }
                        </p>
                    </div>
                    <Link to={"/choose-plan"} className='my-plan-btn'>
                        <button>Upgrade</button>
                    </Link>
                </div>
            </div>


            {/* for mobile icon  */}
            {/* <input hidden className="check-icon" id="check-icon" name="check-icon" type="checkbox" onClick={() => setShowMenu(!showMenu)} />
            <label className="icon-menu" htmlFor="check-icon">
                <div className="bar bar--1"></div>
                <div className="bar bar--2"></div>
                <div className="bar bar--3"></div>
            </label>

            {showMenu &&
                <div className="navbar-menu">
                    <ul className='nav-menu-item'>
                        <Link to={"/"}>
                            <li className='nav-link'>Home</li>
                        </Link>
                        <li className='nav-link' onClick={() => { setShowMenuCat(!showMenuCat); setShowMenuProfile(false) }}>Categories <ChevronDown />
                        </li>

                        {showMenuCat &&
                            <div className="dropdown-menu-content">
                                <ul>
                                    <CategoryItem displayCategory="CMS" sendCategory="CMS" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Web Development" sendCategory="Web Development" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="App Development" sendCategory="Mobile App Development" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Gaming" sendCategory="Games" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Graphic Design" sendCategory="Graphic Designer" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Blockchain and NFTs" sendCategory="Blockchain/NFTs" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="AI/ML/Computer Vision" sendCategory="AI/ML/Computer Vision" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="UI/UX Design" sendCategory="UI/UX Designer" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="DevOps/Database" sendCategory="DevOps/Database" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Digital Marketing" sendCategory="Digital Marketing" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Content Writing" sendCategory="Content Writing" handleCategoryClick={handleCategoryClick} />
                                    <CategoryItem displayCategory="Google Ads and SEO" sendCategory="Google Ads/SEO" handleCategoryClick={handleCategoryClick} />
                                </ul>
                            </div>
                        }

                        <Link to={"/jobs"}>
                            <li className='nav-link'>Jobs</li>
                        </Link>

                        <li onClick={() => { setShowMenuProfile(!showMenuProfile); setShowMenuCat(false) }}>{ojiiz_user && ojiiz_user.userName}<ChevronDown /></li>
                        {showMenuProfile && (
                            <div className="nav-menu-profile-dropdown">
                                <ul>
                                    <Link to={"/profile"} onClick={() => setDropdownVisible(false)}>
                                        <li>Profile</li>
                                    </Link>
                                    <Link to={"/saved-jobs"} onClick={() => setDropdownVisible(false)}>
                                        <li>Saved Jobs</li>
                                    </Link>
                                    <Link to={"/choose-plan"} onClick={() => setDropdownVisible(false)}>
                                        <li>Choose Plan</li>
                                    </Link>
                                    <li onClick={handleClickLogout}>Logout</li>
                                </ul>
                            </div>
                        )}
                    </ul>
                </div>
            } */}
        </div>
    );
};

export default NavBar;
