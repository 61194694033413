// FilterContext.js
import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [dateFilters, setDateFilters] = useState([]);

    const clearCategoryFilters = () => setCategoryFilters([]);
    const clearDateFilters = () => setDateFilters([]);

    const updateCategoryFilters = (value, checked) => {
        setCategoryFilters(prevFilters => checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value));
    };

    const updateDateFilters = (value, checked) => {
        setDateFilters(prevFilters => checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value));
    };

    return (
        <FilterContext.Provider value={{
            categoryFilters,
            dateFilters,
            updateCategoryFilters,
            updateDateFilters,
            clearCategoryFilters,
            clearDateFilters,
            setCategoryFilters,
            setDateFilters
        }}>
            {children}
        </FilterContext.Provider>
    );
};

export const useFilterContext = () => useContext(FilterContext);
