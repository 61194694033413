import React, { useState } from 'react';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { MdSearch } from "react-icons/md";
// import vector from '../assets/demo-video.svg';
// import VideoPopup from './VideoPopup';

const Hero = ({ home, searchValue, isJobPage }) => {
    const [searchQuery, setSearchQuery] = useState(searchValue || '');
    const navigate = useNavigate();
    // const [isOpen, setIsOpen] = useState(false); // State to control video popup visibility

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/jobs', { state: { searchQuery: searchQuery } });
    };

    return (
        <div className='Hero-section'>
            <NavBar home={home} />

            <div className="hero-content">
                <div className="hero-heading">
                    {!isJobPage &&
                        <div className="heading-content">
                            <h1>{home && <span>Welcome <br /></span>}Find Your Relevant Jobs Today</h1>
                            <p>Thousands of jobs in the computer, engineering and technology sectors are waiting for you.</p>
                        </div>
                    }
                    {/* <div className="demo-video">
                        <div className="demo-btn">
                            <button onClick={() => setIsOpen(true)}>demo here</button>
                        </div>
                        <img src={vector} alt="" />
                    </div> */}

                </div>

                <div className="hero-search">
                    <form onSubmit={handleSubmit} className="search-form">
                        <input
                            type="text"
                            name="search"
                            value={searchQuery}
                            onChange={handleInputChange}
                            placeholder='Search by Job Title...'
                        />
                        {/* Conditional rendering based on screen size */}
                        <button type="submit" className="search-button" disabled={searchQuery.length === 0}>
                            <span>
                                Search Jobs
                            </span>
                            <MdSearch className="search-icon" size={24} />
                        </button>
                    </form>
                </div>

            </div>

            {/* {isOpen && (
                <VideoPopup isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
            )} */}
        </div>
    );
};

export default Hero;
