import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'; // Import the CSS file
import img from '../assets/not-found.png'

const NotFound = () => {
    return (
        <div className="not-found-container">
            <img src={img} alt="404 Not Found" className="not-found-image" />
            <h1 className="not-found-title">404 - Page Not Found</h1>
            <p className="not-found-text">Sorry, the page you are looking for does not exist.</p>
            <Link to="/" className="primary-btn">Go to Homepage</Link>
        </div>
    );
};

export default NotFound;
