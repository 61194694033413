import React from 'react'

const NameAnimation = () => {
    return (
        /* From Uiverse.io by akshat-patel28 */
        <div className="jobDetailLoader">

            <div className="name-loader">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                    <text
                        x="50%"
                        y="60%"
                        textAnchor="middle"
                        fill="black"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        O
                    </text>
                </svg>
            </div>

            <div className="name-loader">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                    <text
                        x="50%"
                        y="60%"
                        textAnchor="middle"
                        fill="black"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        J
                    </text>
                </svg>
            </div>

            <div className="name-loader">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                    <text
                        x="50%"
                        y="60%"
                        textAnchor="middle"
                        fill="black"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        I
                    </text>
                </svg>
            </div>

            <div className="name-loader">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                    <text
                        x="50%"
                        y="60%"
                        textAnchor="middle"
                        fill="black"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        I
                    </text>
                </svg>
            </div>

            <div className="name-loader">
                <svg viewBox="0 0 80 80">
                    <rect x="8" y="8" width="64" height="64"></rect>
                    <text
                        x="50%"
                        y="60%"
                        textAnchor="middle"
                        fill="black"
                        fontSize="24"
                        fontWeight="bold"
                    >
                        Z
                    </text>
                </svg>
            </div>

        </div>
    )
}

export default NameAnimation
