import React from 'react'
import CountUp from 'react-countup';

const AnimatedCounter = ({ value }) => {
    return (
        <span>
            <CountUp
                end={value}
                decimal=","
                duration={1.5}
            />
        </span>
    )
}

export default AnimatedCounter
