import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('');
    const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { token } = useParams();
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    const validatePasswordStrength = (password) => {
        const lengthCriteria = password.length >= 8;
        const numberCriteria = /[0-9]/.test(password);
        const uppercaseCriteria = /[A-Z]/.test(password);
        const lowercaseCriteria = /[a-z]/.test(password);
        const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if (!lengthCriteria) return "Password must be at least 8 characters long.";
        if (!numberCriteria) return "Password must contain at least one number.";
        if (!uppercaseCriteria) return "Password must contain at least one uppercase letter.";
        if (!lowercaseCriteria) return "Password must contain at least one lowercase letter.";
        if (!specialCharacterCriteria) return "Password must contain at least one special character.";

        return '';
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        const validationMessage = validatePasswordStrength(newPassword);
        setPasswordStrengthMessage(validationMessage);
        if (confirmPassword && newPassword !== confirmPassword) {
            setPasswordMatchMessage('Passwords do not match');
        } else {
            setPasswordMatchMessage('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        if (password && newConfirmPassword !== password) {
            setPasswordMatchMessage('Passwords do not match');
        } else {
            setPasswordMatchMessage('');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordMatchMessage) {
            toast.warning(passwordMatchMessage);
            return;
        }

        if (passwordStrengthMessage) {
            toast.warning(passwordStrengthMessage);
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${API_URL}/api/ojiiz/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
                body: JSON.stringify({ token, password }),
            });

            if (response.ok) {
                toast.success('Password has been updated');
                setTimeout(() => {
                    navigate('/sign-in');
                }, 2000);
            } else {
                const data = await response.json();
                toast.error(data.message || 'Error updating password');
            }
        } catch (error) {
            toast.error('Error updating password');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='sign-in'>
            <ToastContainer />
            <div className="forgot-mod">
                <div className="forgot-content">
                    <h1>Create a New Password</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="password-field">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder='New Password'
                                value={password}
                                onChange={handlePasswordChange}
                                required
                            />
                            {showPassword ? <FaEyeSlash className="toggle-password" onClick={toggleShowPassword} /> : <FaEye className="toggle-password" onClick={toggleShowPassword} />}
                        </div>
                        <div className="password-field">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder='Confirm New Password'
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                required
                            />
                            {showConfirmPassword ? <FaEyeSlash className="toggle-password" onClick={toggleShowConfirmPassword} /> : <FaEye className="toggle-password" onClick={toggleShowConfirmPassword} />}
                        </div>
                        {passwordStrengthMessage && <p className="warning">{passwordStrengthMessage}</p>}
                        {passwordMatchMessage && <p className="warning">{passwordMatchMessage}</p>}
                        <button type="submit" disabled={isLoading} className='primary-btn'>
                            {isLoading ? 'Processing...' : 'Submit'}
                        </button>
                    </form>
                </div>
            </div>
            <div className="sign-content"></div>
            <div className="fade-in"></div>
        </div>
    );
};

export default ResetPassword;
