import React from 'react'

const FeatureJobLoader = () => {
    return (
        <div className="more-job-section">
            <div className="skeleton-title"></div>
            <p className="skeleton-text"></p>
            <div className="more-job-row">
                <div className="more-job">
                    <p className="skeleton-text"></p>
                    <button className="skeleton-text"></button>
                </div>
                <div className="more-job">
                    <p className="skeleton-text"></p>
                    <button className="skeleton-text"></button>
                </div>
                <div className="more-job">
                    <p className="skeleton-text"></p>
                    <button className="skeleton-text"></button>
                </div>
                <div className="more-job">
                    <p className="skeleton-text"></p>
                    <button className="skeleton-text"></button>
                </div>
            </div>
        </div>
    )
}

export default FeatureJobLoader
