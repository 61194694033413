import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

const StripeForm = ({ onPaymentSuccess, totalAmount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [cardName, setCardName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) return; // Stripe.js has not loaded yet

        setLoading(true);
        setError('');
        setSuccessMessage('');

        // Get individual elements
        const cardNumberElement = elements.getElement(CardNumberElement);


        // Create payment method using CardNumberElement
        const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                name: cardName,
                // You can add other billing details here, like email, address, etc.
            },
        });

        // Handle payment error
        if (paymentError) {
            setError(paymentError.message);
        } else {
            setError('');
            onPaymentSuccess(paymentMethod.id); // Pass paymentMethod.id to the parent
            setSuccessMessage('Card added successfully!');
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className="credit-card-form">

            <div className="card-name">
                <label htmlFor="card-name">
                    Card Holder Name
                    <input
                        type="text"
                        id="card-name"
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                        placeholder="Fenix"
                        required
                    />
                </label>
            </div>

            <div className="card-number">
                <label htmlFor="card-number">Card Number</label>
                <CardNumberElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#333',
                                '::placeholder': {
                                    color: '#ccc',
                                },
                            },
                            invalid: {
                                color: '#fa755a',
                                iconColor: '#fa755a',
                            },
                        },
                    }}
                />
            </div>

            <div className="stripe-row">
                <div className="cvc">
                    <label htmlFor="card-cvc">CVC</label>
                    <CardCvcElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#333',
                                    '::placeholder': {
                                        color: '#ccc',
                                    },
                                },
                                invalid: {
                                    color: '#fa755a',
                                    iconColor: '#fa755a',
                                },
                            },
                        }}
                    />
                </div>


                <div className="expiry">
                    <label htmlFor="card-expiry">Expiration (MM/YY)</label>
                    <CardExpiryElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#333',
                                    '::placeholder': {
                                        color: '#ccc',
                                    },
                                },
                                invalid: {
                                    color: '#fa755a',
                                    iconColor: '#fa755a',
                                },
                            },
                        }}
                    />
                </div>


            </div>

            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <div className="checkout-btn">
                <p>Total: ${totalAmount.toFixed(2)}</p>
                <button
                    type="submit"
                    disabled={loading || !stripe}
                >
                    {loading ? 'Processing...' : 'PURCHASE'}
                </button>
            </div>
        </form >
    );
};

export default StripeForm;
