// FilterOptions.js
import React from 'react';

const FilterOptions = ({ options, checkedFilters, onChange, jobCounts, isLoading }) => {
    if (!options) {
        return null; // Return null or handle the case where options is not defined
    }

    return (
        <div className="checkbox">
            {options.map(option => (
                <label key={option.id} htmlFor={option.id} className="cyberpunk-checkbox-label">
                    <input
                        className="cyberpunk-checkbox"
                        type="checkbox"
                        name={option.name}
                        id={option.id}
                        value={option.value}
                        onChange={onChange}
                        checked={checkedFilters.includes(option.value)}
                        disabled={isLoading}
                    />
                    {option.label}
                    <strong style={{ color: "var(--primary-color" }}>
                        {jobCounts && option.name === 'jobCategory' ? (
                            ` (${jobCounts.find(count => count._id === option.value)?.count || 0})`
                        ) : ''}
                    </strong>

                </label>
            ))}
        </div>
    );
};

export default FilterOptions;
