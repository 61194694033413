import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AnimatedCounter, Footer, LineChart, NavBar, SideBar } from '../components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaCoins } from "react-icons/fa";
import map from '../assets/world-map.png';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { useAuthContext } from '../hooks/useAuthContext';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import saveJobImg from '../assets/save-job.png';

// Import all icons
import AIIcon from '../assets/job-icon/AI.png';
import CMSIcon from '../assets/job-icon/CMS.png';
import CWIcon from '../assets/job-icon/CW.png';
import DevopsIcon from '../assets/job-icon/Devops.png';
import DMIcon from '../assets/job-icon/DM.png';
import GamingIcon from '../assets/job-icon/Gaming.png';
import GDIcon from '../assets/job-icon/GD.png';
import AppIcon from '../assets/job-icon/App.png';
import SEOIcon from '../assets/job-icon/SEO.png';
import UIIcon from '../assets/job-icon/UI.png';
import WebIcon from '../assets/job-icon/Web.png';
import BlockchainIcon from '../assets/job-icon/Blockchain.png';
import OthersIcons from '../assets/job-icon/Others.png';

ChartJS.register(ArcElement, Tooltip);

// Mapping between job categories and icons
const categoryIcons = {
    'AI/ML/Computer Vision': AIIcon,
    'CMS': CMSIcon,
    'Content Writing': CWIcon,
    'DevOps/Database': DevopsIcon,
    'Digital Marketing': DMIcon,
    'Games': GamingIcon,
    'Graphic Designer': GDIcon,
    'Mobile App Development': AppIcon,
    'Google Ads/SEO': SEOIcon,
    'UI/UX Designer': UIIcon,
    'Web Development': WebIcon,
    'Blockchain/NFTs': BlockchainIcon,
    'Other': OthersIcons

};

// SavedJobs Component
const SavedJobs = React.memo(({ savedJobs, truncateText }) => (
    <div className="saved-jobs">
        <div className="saved-jobs-header">
            <p>Saved Jobs</p>
            <Link to={"/saved-jobs"}>
                <button>View All</button>
            </Link>
        </div>
        <div className="saved-jobs-body">
            {savedJobs && savedJobs.length > 0 ? savedJobs.slice(0, 4).map((job) => (
                <Link to={`/jobs-detail/${job._id}`} key={job._id}>
                    <div className="save-job-post">
                        <div className="building-icon">
                            <img src={categoryIcons[job.jobCategory]} alt={job.jobCategory} />
                        </div>
                        <p>{truncateText(job.jobTitle, 50)}</p>
                    </div>
                </Link>
            )) : (
                <img src={saveJobImg} alt="No saved jobs" width={300} className='no-item' />
            )}
        </div>
    </div>
));

// Stats Component
const Stats = React.memo(({ totalJob, usedCredit, totalCredit, progress, savedJobs }) => (
    <div className="stats-col">
        <Link to={"/jobs"}>
            <div className="stats">
                <p>Total Jobs</p>
                <br />
                <span>
                    <AnimatedCounter value={totalJob} />
                </span>
            </div>
        </Link>
        <Link to={"/overview"}>
            <div className="stats overview-stats">
                <div className="stats-data">
                    <p>Plan Overview</p>
                    <div>{`${usedCredit}/${totalCredit}`} <span>oz</span></div>
                </div>
                <CircularProgressbar
                    value={progress}
                    text={`${Math.round(progress)}%`}
                    className='circle-bar'
                    styles={{
                        path: { stroke: '#213157' },
                        text: { fill: '#213157' },
                    }}
                />
            </div>
        </Link>
        <Link to={"/saved-jobs"}>
            <div className="stats">
                <p>Saved Jobs</p>
                <br />
                <span>{savedJobs.length ?? 0}</span>
            </div>
        </Link>
    </div>
));

// CountryStats Component
const CountryStats = React.memo(() => (
    <div className="country-stats" id='jobDetailSection'>
        <div className="country-stats-header">
            <p>Live Fields</p>
            <button>By Country</button>
        </div>
        <div className="country-stats-body">
            <img src={map} alt="World map" />
        </div>
    </div>
));

// CreditInfo Component
const CreditInfo = React.memo(({ data, totalCredit, usedCredit }) => (
    <div className="creds-stats">
        <div className="creds-header">
            Credit Info
        </div>
        <div className="creds-body">
            <div className="doughnut-chart">
                <Doughnut
                    data={data}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,  // Hide the legend
                            },
                        },
                    }} />
            </div>
            <div className="creds-details">
                <div className="creds-color">
                    <div className="color-name">
                        <span style={{ backgroundColor: '#7672EC' }}></span>
                        <h4>Remaining Credits</h4>
                    </div>
                    <div className='cred-value'>
                        <FaCoins color='#7672EC' />
                        {`${totalCredit - usedCredit}/${totalCredit}`}
                    </div>
                </div>
                <div className="creds-color">
                    <div className="color-name">
                        <span style={{ backgroundColor: '#FFC2FD' }}></span>
                        <h4>Used Credits</h4>
                    </div>
                    <div className='cred-value'>
                        <FaCoins color='#7672EC' />
                        {`${usedCredit}/${totalCredit}`}
                    </div>
                </div>
            </div>
        </div>
    </div>
));

const Overview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [totalJob, setTotalJob] = useState(0);
    const [savedJobs, setSavedJobs] = useState([]);
    const { ojiiz_user } = useAuthContext();
    // const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_BASE_API_URL;
    const AUTH_API_KEY = process.env.REACT_APP_AUTH_API_KEY;

    const fetchSavedJobs = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}/api/ojiiz/user-saved-jobs/${ojiiz_user.userName}`, {
                headers: {
                    'x-api-key': AUTH_API_KEY,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch saved jobs');
            }

            const data = await response.json();

            setUserData(data.user);
            setSavedJobs(data.savedJobs);
            setTotalJob(data.totalJobs);
        } catch (error) {
            toast.error('Error fetching saved jobs: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    }, [API_URL, AUTH_API_KEY, ojiiz_user.userName]);



    useEffect(() => {
        fetchSavedJobs();
    }, [fetchSavedJobs]);

    const totalCredit = useMemo(() => userData?.totalCredit ?? 0, [userData]);
    const usedCredit = useMemo(() => userData?.usedCredit ?? 0, [userData]);
    const progress = useMemo(() => totalCredit ? ((totalCredit - usedCredit) / totalCredit) * 100 : 0, [totalCredit, usedCredit]);

    const doughnutData = useMemo(() => ({
        labels: ['Remaining Credits', 'Used Credits'],
        datasets: [
            {
                data: [totalCredit - usedCredit, usedCredit],
                backgroundColor: ['#7672EC', '#FFC2FD'],
                hoverBackgroundColor: ['#7672EC', '#FFC2FD'],
                borderWidth: 1,
                weight: 1,
            },
        ],
    }), [totalCredit, usedCredit]);

    const truncateText = useCallback((content, maxLength) => {
        if (content && maxLength) {
            return content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;
        }
        return '';
    }, []);


    return (
        <div>
            <div className="jobDetail-navbar">
                <NavBar home={true} />
                <h1>Overview</h1>
            </div>
            <ToastContainer />
            <div className="overview-content">
                <SideBar />
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <>
                        <div className="overview-detail">
                            <div className="overview-row">
                                <Stats
                                    totalJob={totalJob}
                                    usedCredit={usedCredit}
                                    totalCredit={totalCredit}
                                    progress={progress}
                                    savedJobs={savedJobs}
                                />
                                <CountryStats />
                                <div className="video-side" style={{width:"40%"}}>

                                    <CreditInfo
                                        data={doughnutData}
                                        totalCredit={totalCredit}
                                        usedCredit={usedCredit}
                                    />
                                </div>
                            </div>
                            <div className="overview-jobs-stats">
                                <div className="job-chart">
                                    <LineChart />
                                </div>
                                <SavedJobs
                                    savedJobs={savedJobs}
                                    truncateText={truncateText}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Overview;
