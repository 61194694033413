import React from 'react'

const Footer = () => {
    return (
        <div className='footer'>
            <p>2024 © Ojiiz</p>
            <p>All right are reserved by Ojiiz</p>
        </div>
    )
}

export default Footer
