import { useRef, useEffect } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

const Tour = () => {
    const intro = useRef(null);

    useEffect(() => {
        // Initialize the tour
        intro.current = introJs();
        intro.current.setOptions({
            steps: [
                {
                    intro: "Welcome to your first job!",
                },
                {
                    element: '#jobDetailSection',
                    intro: 'Here is the job description.',
                },
                {
                    element: '#accessButton',
                    intro: `
                    <p><strong>Click the button to unlock project details:</strong></p>
                    </br>
                    <p>View the company name.</p>
                    </br>
                    <p>Get the decision maker's email to pitch directly.</p>
                    </br>
                    <p>Access the decision maker's LinkedIn profile to connect directly.</p>
                    </br>
                    <p>Get the phone number and call directly.</p>
                    `,
                }
            ],
            showProgress: true,
            showBullets: false,
            exitOnOverlayClick: false,
            doneLabel: "Finish",
        });

        // Start the tour
        intro.current.start();

        // Cleanup
        return () => {
            if (intro.current) intro.current.exit();
        };

    }, []);

    return null;
};

export default Tour;
