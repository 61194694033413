import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { FaCreditCard, FaLock } from 'react-icons/fa6';
import { IoCloseOutline } from 'react-icons/io5';
import StripeForm from './StripeForm';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Load Stripe with your publishable key
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!STRIPE_PUBLISHABLE_KEY) {
    console.error("Stripe publishable key is missing.");
}

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const BillingForm = ({ onClose, updatedUserData, redirectUrl }) => {
    const [selectedOption, setSelectedOption] = useState('new');
    const [savedCard, setSavedCard] = useState(null);
    const [isSavedCard, setIsSavedCard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_BASE_API_URL;

    useEffect(() => {
        const fetchSavedCard = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/api/ojiiz/get-saved-payment-method/${updatedUserData.userName}`, {
                    headers: { 'x-api-key': process.env.REACT_APP_AUTH_API_KEY },
                });

                if (response.status === 500) {
                    throw new Error('Failed to retrieve payment method');
                } else if (response.status === 204) {
                    // No saved card found, so no need to show any message
                    setSelectedOption('new');
                    setIsSavedCard(false);
                    return;
                }

                const data = await response.json();
                const { paymentMethodId, last4 } = data;

                if (paymentMethodId) {
                    setSavedCard({ id: paymentMethodId, last4 });
                    setSelectedOption('saved');
                    setIsSavedCard(true);
                }
            } catch (error) {
                console.error(error);
                setPaymentError(error.message || 'Failed to load saved card');
                setSelectedOption('new');
                setIsSavedCard(false);
            } finally {
                setLoading(false);
            }
        };

        if (updatedUserData) {
            fetchSavedCard();
        }
    }, [updatedUserData, API_URL]);

    const handlePayment = async (paymentMethodId) => {
        setLoading(true);
        setPaymentError('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${API_URL}/api/ojiiz/process-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_AUTH_API_KEY,
                },
                body: JSON.stringify({
                    paymentMethodId,
                    isSavedCard,
                    userData: updatedUserData,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Payment failed. Please try again.');
            }

            const result = await response.json();

            if (result.success) {
                toast.success('Payment successful! Thank you for your purchase.');


                if (redirectUrl === 'plan') {
                    setTimeout(() => {
                        navigate('/sign-in');
                    }, 2000);
                } else {
                    const ojiizUser = JSON.parse(localStorage.getItem('ojiiz_user')) || {};
                    ojiizUser.planType = result.paymentIntent.metadata.planType;
                    localStorage.setItem('ojiiz_user', JSON.stringify(ojiizUser));
                    window.location.href = redirectUrl;
                }


            } else {
                setPaymentError('Payment failed. Please try again.');
            }
        } catch (error) {
            setPaymentError(error.message);
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const totalAmount = updatedUserData?.totalPrice || 0;

    return (

        <div className="billing-modal">
            <div className="billing-content">
                <div className="billing-modal-header">
                    Add Billing Method
                    <button className="close-button" onClick={onClose} aria-label="Close">
                        <IoCloseOutline />
                    </button>
                </div>
                <div className="billing-modal-body">
                    <ToastContainer />
                    <div className="billing-card-detail">
                        {loading ? (
                            <div className="chart-loader">
                                <div className="dot-spinner">
                                    {/* Spinner UI */}
                                    {Array(8).fill().map((_, index) => (
                                        <div key={index} className="dot-spinner__dot"></div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <>
                                {savedCard && (
                                    <label htmlFor="save">
                                        <input
                                            type="radio"
                                            name="card"
                                            id="save"
                                            checked={selectedOption === 'saved'}
                                            onChange={() => {
                                                setSelectedOption('saved');
                                                setIsSavedCard(true);
                                            }}
                                        />
                                        Already Saved Credit Card
                                    </label>
                                )}
                                {selectedOption === 'saved' && savedCard && (
                                    <div className="prev-card">
                                        <FaCreditCard className="icon" />
                                        <input type="text" value={`**** **** **** ${savedCard.last4}`} readOnly />
                                    </div>
                                )}
                                <label htmlFor="new">
                                    <input
                                        type="radio"
                                        name="card"
                                        id="new"
                                        checked={selectedOption === 'new'}
                                        onChange={() => {
                                            setSelectedOption('new');
                                            setIsSavedCard(false);
                                        }}
                                    />
                                    Add New Credit Card
                                </label>
                                {selectedOption === 'new' && (
                                    <Elements stripe={stripePromise}>
                                        <StripeForm onPaymentSuccess={handlePayment} totalAmount={totalAmount} />
                                    </Elements>
                                )}
                            </>
                        )}
                        {paymentError && <div className="error-message">{paymentError}</div>}
                        {successMessage && <div className="success-message">{successMessage}</div>}

                        {selectedOption === 'saved' && (
                            <div className="checkout-btn">
                                <p>Total: ${totalAmount.toFixed(2)}</p>
                                <button
                                    type="button"
                                    onClick={() => handlePayment(isSavedCard ? savedCard.id : null)}
                                    disabled={loading || (selectedOption === 'saved' && !savedCard)}
                                >
                                    {loading ? 'Processing...' : 'PURCHASE'}
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="checkout-detail-card">
                        <p>You’re paying,</p>
                        <h3>${totalAmount.toFixed(2)}</h3>
                        <div className="package-detail">
                            <div className="row">
                                {/* <b>{updatedUserData?.planType}</b> */}
                                <p>{updatedUserData?.selectedPlan} oz</p>
                            </div>
                        </div>
                        <div className="payment-text">
                            <span>
                                <p>Payments are SSL encrypted so that your credit card and payment details stay safe.</p>
                                <FaLock />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BillingForm;
