import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from "./context/AuthContext";
import { AdminAuthContextProvider } from './admin/pages/account/AdminAuthContext';
import { FilterProvider } from './context/FilterContext';
import JobsProvider from "./context/JobContext";

import ReactPixel from 'react-facebook-pixel';
import { UserCreditProvider } from './context/UserCreditContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

const options = {
  autoConfig: true,    // set pixel's autoConfig
  debug: false,        // enable logs
};

ReactPixel.init('992996922227880', options); // Replace 'YOUR_PIXEL_ID' with your actual Pixel ID
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AdminAuthContextProvider>
        <AuthContextProvider>
          <UserCreditProvider>
            <JobsProvider>
              <FilterProvider>
                <App />
              </FilterProvider>
            </JobsProvider>
          </UserCreditProvider>
        </AuthContextProvider>
      </AdminAuthContextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
