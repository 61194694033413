import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { AnimatedCounter, Footer, Jobs, NavBar, SkeletonPageLoader } from '../../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import noJob from '../../assets/no-job.png';
import FilterOptions from './FilterOptions';
import { JobsContext } from '../../context/JobContext';
import { categoryFiltersOption, dateFiltersOption } from './Options';
import { useFilterContext } from '../../context/FilterContext';
import { useUserCredit } from '../../context/UserCreditContext';
import debounce from 'lodash.debounce';
import { MdSearch } from "react-icons/md";
import { IoClose } from 'react-icons/io5';

const JobPages = () => {
    const { Credit } = useUserCredit();
    const { jobs, totalJobs, isLoading, currentPage, totalPages, fetchJobs, setCurrentPage, pageLimit, setPageLimit, jobCounts } = useContext(JobsContext);
    const { categoryFilters, dateFilters, clearCategoryFilters, clearDateFilters, updateCategoryFilters, updateDateFilters } = useFilterContext();
    const navigate = useNavigate();
    const location = useLocation();

    let { category = '', searchQuery: initialSearchQuery = '' } = location.state || {};

    const [sortBy, setSortBy] = useState('newest');
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const debouncedFetchJobs = useCallback(debounce((fetchData) => {
        fetchData();
    }, 1000), []);



    useEffect(() => {
        setSearchQuery(initialSearchQuery);
    }, [initialSearchQuery]);

    useEffect(() => {
        debouncedFetchJobs(() => {
            const fetchData = async () => {
                if (searchQuery.length > 0) {
                    await fetchJobs(1, {
                        jobCategory: [],
                        jobDate: [dateFilters],
                    }, sortBy, searchQuery, true, pageLimit);
                    clearCategoryFilters();
                    clearDateFilters();
                } else if (category) {
                    clearCategoryFilters();
                    clearDateFilters();
                    updateCategoryFilters(category, true);
                    await fetchJobs(1, {
                        jobCategory: [category],
                        jobDate: [dateFilters],
                    }, sortBy, '', false, pageLimit);
                } else {
                    clearCategoryFilters();
                    clearDateFilters();
                    await fetchJobs(1, {
                        jobCategory: [],
                        jobDate: [],
                    }, '', '', false, pageLimit);
                }
            };
            fetchData();
        });
    }, [category]);

    const handleSortChange = useCallback((event) => {
        const selectedSortBy = event.target.value;
        setSortBy(selectedSortBy);
        fetchJobs(currentPage, {
            jobCategory: categoryFilters,
            jobDate: dateFilters,
        }, selectedSortBy, searchQuery, !!searchQuery, pageLimit);
    }, [fetchJobs, currentPage, searchQuery, categoryFilters, dateFilters, pageLimit]);

    const handleCategoryClearFilters = () => {
        setSelectedCategory('');
        clearCategoryFilters();
        fetchJobs(1, { jobCategory: [], jobDate: dateFilters }, sortBy, '', false, pageLimit);
    };

    const handleDateClearFilters = () => {
        setSelectedDate('');
        clearDateFilters();
        fetchJobs(1, { jobCategory: categoryFilters, jobDate: [] }, sortBy, searchQuery, !!searchQuery, pageLimit);
    };

    const handleCategoryChange = (event) => {
        const selectedValue = event.target.value || event.target.name;
        setSelectedCategory(selectedValue);
        setSearchQuery('')
        if (categoryFilters.includes(selectedValue)) {
            // If the filter is already applied, remove it
            updateCategoryFilters(selectedValue, false);
            fetchJobs(1, {
                jobCategory: categoryFilters.filter(filter => filter !== selectedValue),
                jobDate: dateFilters,
            }, sortBy, '', false, pageLimit);
        } else {
            // If the filter is not applied, add it
            updateCategoryFilters(selectedValue, true);
            fetchJobs(1, {
                jobCategory: [...categoryFilters, selectedValue],
                jobDate: dateFilters,
            }, sortBy, '', false, pageLimit);
        }
    };

    const handleDateChange = (event) => {
        const selectedValue = event.target.value || event.target.name;
        setSelectedDate(selectedValue);

        if (dateFilters.includes(selectedValue)) {
            // If the filter is already applied, remove it
            updateDateFilters(selectedValue, !!searchQuery);
            fetchJobs(1, {
                jobCategory: categoryFilters,
                jobDate: dateFilters.filter(filter => filter !== selectedValue),
            }, sortBy, searchQuery, !!searchQuery, pageLimit);
        } else {
            // If the filter is not applied, add it
            updateDateFilters(selectedValue, true);
            fetchJobs(1, {
                jobCategory: categoryFilters,
                jobDate: [...dateFilters, selectedValue],
            }, sortBy, searchQuery, !!searchQuery, pageLimit);
        }
    };


    const handleSearchInputChange = useCallback((value) => {
        setSearchQuery(value);
        clearCategoryFilters();
        debouncedFetchJobs(() => fetchJobs(1, {
            jobCategory: [],
            jobDate: dateFilters
        }, sortBy, value, true, pageLimit));
    }, [clearCategoryFilters, dateFilters, sortBy, pageLimit, fetchJobs]);

    const nextPage = useCallback(() => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            fetchJobs(currentPage + 1, { jobCategory: categoryFilters, jobDate: dateFilters }, sortBy, searchQuery, !!searchQuery, pageLimit);
        }
    }, [currentPage, totalPages, fetchJobs, sortBy, searchQuery]);

    const prevPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            fetchJobs(currentPage - 1, { jobCategory: categoryFilters, jobDate: dateFilters }, sortBy, searchQuery, !!searchQuery, pageLimit);
        }
    }, [currentPage, fetchJobs, sortBy, searchQuery]);

    const goToPage = useCallback((pageNumber) => {
        setCurrentPage(pageNumber);
        fetchJobs(pageNumber, {
            jobCategory: categoryFilters,
            jobDate: dateFilters,
        }, sortBy, searchQuery, !!searchQuery, pageLimit);
    }, [fetchJobs, setCurrentPage, categoryFilters, dateFilters, sortBy, searchQuery, pageLimit]);

    const handleJobClick = useCallback(() => {
        toast.info('You have no more oz.');
        setTimeout(() => {
            navigate('/choose-plan');
        }, 2000);
    }, [navigate]);

    const renderJobs = useCallback(() => {
        if (jobs.length === 0 && !isLoading) {
            return (
                <div style={{ margin: "0 auto" }}>
                    <img src={noJob} alt="" width={500} className="no-item" />
                </div>
            );
        }

        return jobs.map((job) => (
            <React.Fragment key={job._id}>
                {Credit <= 0 ? (
                    <div
                        onClick={!isLoading ? handleJobClick : undefined}
                        style={{
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                            pointerEvents: isLoading ? 'none' : 'auto',
                        }}
                    >
                        <Jobs job={job} />
                    </div>
                ) : (
                    <Link
                        to={!isLoading ? `/jobs-detail/${job._id}` : '#'}
                        target='_blank'
                        style={{
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                            pointerEvents: isLoading ? 'none' : 'auto',
                        }}
                    >
                        <Jobs job={job} />
                    </Link>
                )}
            </React.Fragment>
        ));
    }, [jobs, isLoading, Credit, handleJobClick]);

    // Combine category and date filters into a single array for display
    const activeFilters = useMemo(() => {
        // Create a mapping for date filter options to easily find labels
        const dateFilterLabels = dateFiltersOption.reduce((acc, option) => {
            acc[option.value] = option.label;
            return acc;
        }, {});

        return [
            ...categoryFilters.map(filter => ({ type: 'category', value: filter })),
            ...dateFilters.map(filter => ({ type: 'date', value: dateFilterLabels[filter] || filter })), // Use label instead of value
        ];
    }, [categoryFilters, dateFilters]);


    // Handle removing an individual filter
    const handleRemoveFilter = useCallback((filterToRemove) => {
        if (filterToRemove.type === 'category') {
            updateCategoryFilters(filterToRemove.value, false);
        } else if (filterToRemove.type === 'date') {
            // Find the corresponding value for the label
            const dateFilterValue = dateFiltersOption.find(option => option.label === filterToRemove.value)?.value;
            updateDateFilters(dateFilterValue, false);
        }

        fetchJobs(1, {
            jobCategory: filterToRemove.type === 'category' ? categoryFilters.filter(filter => filter !== filterToRemove.value) : categoryFilters,
            jobDate: filterToRemove.type === 'date'
                ? dateFilters.filter(filter => filter !== dateFiltersOption.find(option => option.label === filterToRemove.value)?.value)
                : dateFilters,
        }, sortBy, searchQuery, !!searchQuery, pageLimit);
    }, [updateCategoryFilters, updateDateFilters, categoryFilters, dateFilters, fetchJobs, sortBy, searchQuery, pageLimit]);


    // to display pagination btn
    const pageButtons = useMemo(() => {

        const buttons = [];
        for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
            buttons.push(
                <button key={i} onClick={() => goToPage(i)} className={currentPage === i ? 'active' : ''}>
                    {i}
                </button>
            );
        }
        return buttons;
    }, [currentPage, totalPages, goToPage]);


    // to handle pageLimit chage
    const handlePageLimitChange = useCallback((event) => {

        const newPageLimit = parseInt(event.target.value, 10);
        setPageLimit(newPageLimit);

        // Fetch jobs with updated pageLimit, preserving current filters
        fetchJobs(1, {
            jobCategory: categoryFilters,
            jobDate: dateFilters,
        }, sortBy, searchQuery, !!searchQuery, newPageLimit);
    }, [fetchJobs, sortBy, searchQuery, categoryFilters, dateFilters]);



    return (
        <div className="job-page">
            <div className="job-page-hero">
                <NavBar home={true} />
                <div className="hero-search">
                    <form className="search-form" onSubmit={(e) => { e.preventDefault(); }}>
                        <input
                            type="text"
                            name="search"
                            value={searchQuery}
                            onChange={(e) => handleSearchInputChange(e.target.value)}
                            placeholder="Search by Job Title..."
                        />

                        <button type="submit" className="search-button" disabled={searchQuery.length === 0}>
                            <span>
                                Search Jobs
                            </span>
                            <MdSearch className="search-icon" size={24} />
                        </button>
                    </form>
                </div>
            </div>
            {isLoading && <div className="loader"></div>}
            <ToastContainer />


            {/* for mobile */}
            <div className="job-filter-bar">
                <div className="horizontal-filter-bar">

                    {/* Mobile - Dropdown UI */}
                    <div className="dropdown-options">
                        <p>Categories</p>
                        <select value={selectedCategory} onChange={handleCategoryChange}>
                            <option value="">Select Category</option>
                            {categoryFiltersOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                    {jobCounts && option.value ? ` (${jobCounts.find(count => count._id === option.value)?.count || 0})` : ''}
                                </option>
                            ))}
                        </select>

                    </div>


                    {/* Mobile - Dropdown UI */}
                    <div className="dropdown-options">
                        <p>Date Posted</p>
                        <select value={selectedDate} onChange={handleDateChange}>
                            <option value="">Select Date</option>
                            {dateFiltersOption.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>
            </div>

            <div className="jobs-list">
                <div className="job-filter">
                    <div className="filter-header">
                        <h2>Filters</h2>
                    </div>

                    <div className="filter-header">
                        <p>Categories</p>

                        <button type="button" className="reset" onClick={() => handleCategoryClearFilters()}>
                            Clear
                        </button>
                    </div>

                    <FilterOptions options={categoryFiltersOption} checkedFilters={categoryFilters} onChange={handleCategoryChange} jobCounts={jobCounts} isLoading={isLoading} />

                    <div className="filter-header">
                        <p>Date Posted</p>

                        <button type="button" className="reset" onClick={() => handleDateClearFilters()}>
                            Clear
                        </button>
                    </div>

                    <FilterOptions options={dateFiltersOption} checkedFilters={dateFilters} onChange={handleDateChange} isLoading={isLoading} />
                </div>

                <div className="job-list">
                    <div className="job-list-header">
                        <h2>
                            <AnimatedCounter value={totalJobs} />
                            {" "}
                            {categoryFilters.length > 0 ? "Filtered Jobs" : "Total Jobs"}
                        </h2>

                        <select className="custom-dropdown" value={sortBy} onChange={handleSortChange}>
                            <option value="newest">Newest</option>
                            <option value="oldest">Oldest</option>
                        </select>

                    </div>

                    {/* to display filter option */}
                    <div className="filter-list">
                        {activeFilters.map((filter, index) => (
                            <div className="filter-item" key={index}>
                                <span>{filter.value}</span> {/* This will now show the label for date filters */}
                                <IoClose className="icon" onClick={() => handleRemoveFilter(filter)} />
                            </div>
                        ))}
                    </div>



                    {isLoading && jobs.length === 0 ? <SkeletonPageLoader /> :
                        <>
                            {renderJobs()}

                        </>
                    }

                    {/* for pagination */}
                    {!isLoading && jobs.length !== 0 &&
                        <>
                            <div className="pagination">
                                <button onClick={prevPage} disabled={currentPage === 1}>
                                    <FaAngleLeft />
                                </button>
                                {pageButtons}
                                <button onClick={nextPage} disabled={currentPage === totalPages}>
                                    <FaAngleRight />
                                </button>
                            </div>

                            <div className="pagination-info">
                                <p>
                                    Page {currentPage} of {totalPages}
                                </p>
                                <select id="pageLimit" className='page-limit-selector' value={pageLimit} onChange={handlePageLimitChange}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </>
                    }


                </div>
            </div>
            <Footer />
        </div>
    );
};

export default JobPages;
